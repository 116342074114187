import {createApp, PropType, ref} from 'vue';
import {connectVue} from '../modules/configureBugsnag';
import configureGlobalComponents from '../modules/configureGlobalComponents';
import RadioButtons from '../components/global/radio-buttons.vue';
import {helpers, required} from '@vuelidate/validators';
import {vrm} from '../modules/regitValidators';
import useVuelidate from '@vuelidate/core';

if (document.getElementsByClassName('landing-form--vrm').length > 0) {
    type JoinFlow = 'car-warranty'|'maintain'|'mot-check'|'mot-history-check'|'recalls'|'sell-your-car'|'settlement-calculator'|'tax-check';

    const app = createApp({
        components: {
            'radio-buttons': RadioButtons,
        },
        props: {
            currentFlow: {type: String as PropType<JoinFlow>, required: true},
            userVehicles: {type: Array as PropType<DropdownOptions>, required: true},
        },
        data() {
            return {
                landingStep: 1,
                serviceTypeOptions: [
                    {value: 'Mot', text: 'I need to a book an MOT'},
                    {value: 'Full Service', text: 'I need to book a service'},
                    {value: 'Full Service and MOT', text: 'I\'m just checking costs'},
                    {value: 'Mot Check', text: 'I\'m just checking when my MOT is due'},
                ],
                isYourCarOptions: [
                    {value: 'yes', text: 'I own this car'},
                    {value: 'no', text: 'I\'m interested in it'},
                ],
            };
        },
        setup(props) {
            const state = {
                vrm: ref(''),
                vrmDropdown: ref(''),
                isyourcar: ref(''),
                serviceType: ref('Mot'),
                addNewReg: ref(false),
            };

            const rules = {
                vrm: {
                    required: helpers.withMessage('Please enter your reg plate', required),
                    vrm: helpers.withMessage('Please enter a valid reg', vrm),
                },
                isyourcar: {},
            };

            if ([
                'mot-check',
                'mot-history-check',
                'tax-check',
            ].includes(props.currentFlow)) {
                rules.isyourcar = {
                    required: helpers.withMessage('Please choose ownership', required),
                };
            }

            const v = useVuelidate(rules, state);
            async function submitChecks(e: Event) {
                e.preventDefault();
                e.stopPropagation();

                const validationPassed = await v.value.$validate();
                if (validationPassed) {
                    (document.getElementById('landing-form__form--vrm') as HTMLFormElement).submit();
                }
            }

            return {
                vrm: state.vrm,
                vrmDropdown: state.vrmDropdown,
                isyourcar: state.isyourcar,
                serviceType: state.serviceType,
                addNewReg: state.addNewReg,

                v$: v,
                submitChecks,
            };
        },
        computed: {
            displayInput(): boolean {
                return this.userVehicles.length <= 1 || this.addNewReg;
            },
            displayDropdown(): boolean {
                if (this.landingStep > 1) {
                    return false;
                }

                return this.userVehicles.length > 1 && !this.addNewReg;
            },
            displayServiceType(): boolean {
                return this.currentFlow === 'maintain';
            },
            isYourCarRequired(): boolean {
                return [
                    'mot-check',
                    'mot-history-check',
                    'tax-check',
                ].includes(this.currentFlow);
            },
            isYourCarPredefined(): boolean {
                return [
                    'maintain',
                    'settlement-calculator',
                    'sell-your-car',
                    'recalls',
                    'car-warranty',
                    'home',
                ].includes(this.currentFlow);
            },
            formTarget(): string {
                if (this.currentFlow === 'car-warranty' || this.currentFlow === 'sell-your-car') {
                    return '_blank';
                }

                return '';
            },
        },
        methods: {
            nextStep() {
                if (this.currentFlow === 'maintain' &&
                    this.landingStep === 1 &&
                    this.v$.vrm.$invalid === false
                ) {
                    this.landingStep = 2;
                    Array.from(document.getElementsByClassName('heronew__titles')).forEach((title) => {
                        Array.from(title.getElementsByTagName('h2')).forEach((header) => {
                            header.innerHTML = 'What can we help you with today?';
                        });
                    });
                }
            },
            addNewCar() {
                this.vrm = '';
                this.addNewReg = true;
            },
        },
        watch: {
            vrmDropdown() {
                this.vrm = this.vrmDropdown;
            },
            vrm() {
                this.nextStep();
            },
        },
        mounted() {
            if (this.currentFlow === 'maintain') {
                this.isyourcar = 'yes';
            }
        },
    }, window.propsData);

    connectVue(app);
    configureGlobalComponents(app);
    app.mount('#landing-form--vrm');
}
