<template>
    <div :class="inputClasses">
        <label v-if="label" class="form__label">{{ label }}</label>
        <ul class="frbg__container" :class="{'frbg__container--stacked': stacked}">
            <li class="frbg__item" v-for="option in options">
                <input
                    type="radio"
                    :id="id + option.value"
                    :name="name"
                    :value="option.value"
                    v-model="inputValue"
                    @change="onChange"
                >
                <label class="frbg__label" :for="id + option.value">{{ option.text }}</label>
            </li>
        </ul>

        <div class="form__error" v-show="errorMessage">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';

export default defineComponent({
    props: {
        'modelValue': {type: String, default: ''},
        'name': {type: String, required: true},
        'options': {type: Array as PropType<DropdownOptions>},
        'label': {type: String, default: ''},

        'small': {type: Boolean, default: false},
        'stacked': {type: Boolean, default: false},
        'css': {type: String, default: ''},
        'validationState': {type: Object},
        'laravelErrors': {type: Array as PropType<ValidationErrors>, default: () => []},
    },
    emits: [
        'update:modelValue',
        'change',
    ],
    data() {
        return {
            id: '',
            inputValue: '',
            previousValue: '',
            valueChanged: false,
        };
    },
    computed: {
        inputClasses(): VueClassMapping {
            const inputClasses = {
                'form__field': true,
                'form__field--radio-button-group': true,
                'form__field--small': this.small,
                'form__field--invalid': !!this.errorMessage,
            } as VueClassMapping;

            if (this.css) {
                inputClasses[this.css] = true;
            }

            return inputClasses;
        },
        errorMessage(): string {
            if (!this.valueChanged && this.laravelErrors.length) {
                return this.laravelErrors[0];
            }

            if (this.validationState && this.validationState.$errors && this.validationState.$errors.length > 0) {
                return this.validationState.$errors[0].$message;
            }

            return '';
        },
    },
    methods: {
        onChange(e: Event) {
            if (this.previousValue === this.inputValue) {
                // if value did not change do not emit events, or sync value
                return;
            }

            this.previousValue = this.inputValue;
            this.$emit('update:modelValue', this.inputValue);
            this.$emit('change', this.inputValue);
            this.valueChanged = true;
        },
    },
    mounted() {
        this.id = this.name + '_' + (Math.random() + 1).toString(36).substring(7);
        this.previousValue = this.modelValue;
    },
});
</script>
