import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form__label"
}
const _hoisted_2 = { class: "frbg__item" }
const _hoisted_3 = ["id", "name", "value"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.inputClasses)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("ul", {
      class: _normalizeClass(["frbg__container", {'frbg__container--stacked': _ctx.stacked}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            id: _ctx.id + option.value,
            name: _ctx.name,
            value: option.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3), [
            [_vModelRadio, _ctx.inputValue]
          ]),
          _createElementVNode("label", {
            class: "frbg__label",
            for: _ctx.id + option.value
          }, _toDisplayString(option.text), 9 /* TEXT, PROPS */, _hoisted_4)
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ], 2 /* CLASS */),
    _withDirectives(_createElementVNode("div", { class: "form__error" }, _toDisplayString(_ctx.errorMessage), 513 /* TEXT, NEED_PATCH */), [
      [_vShow, _ctx.errorMessage]
    ])
  ], 2 /* CLASS */))
}